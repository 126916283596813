const makeItButton = require('@isoftdata/button')
const makeItModal = require('@isoftdata/modal')
const makeItInput = require('@isoftdata/input')
const event = require('@isoftdata/browser-event')
const { ClientRole } = require('socket-server-api-constants')

module.exports = ({ stateRouter, socket, socketEmit, mediator }) => {
	stateRouter.addState({
		name: 'login',
		route: '/login',
		template: {
			template: require('./login.html'),
			data: {
				loginModalShown: true,
				selectAuctionModalShown: false,
				selectAuctionList: [],
				selectRingList: [],
				selectedAuctionIndex: 0,
				errorMessage: '',
				authenticationToken: '',
			},
			components: {
				itButton: makeItButton(),
				itInput: makeItInput({ twoway: true, lazy: false }),
				loginModal: makeItModal(),
				selectAuctionModal: makeItModal(),
			},
			onrender() {
				this.find('#login-username').select()
			},
			async login(username, password, forBroadcast = false) {
				const ractive = this

				const { successful, authToken, accountId } = await socketEmit('login', {
					username,
					password,
					role: ClientRole.CLERK,
				})

				if (successful) {
					ractive.set({ authenticationToken: authToken, userId: accountId })
					socket.emit('get auctions', {}, auctionList => {
						ractive.set({
							selectAuctionList: auctionList,
							loginModalShown: false,
							selectAuctionModalShown: true,
							forBroadcast,
						})
					})
				} else {
					ractive.set({ errorMessage: 'Invalid username or password. Try again.' })
					ractive.find('#login-password').select()
				}
			},
			selectAuction(auction, ring, forBroadcast) {
				const ractive = this
				const authenticationToken = ractive.get('authenticationToken')

				ractive.set({ currentAuction: auction, selectAuctionModalShown: false }).then(() => {
					if (!ring && auction.ringList && auction.ringList.length > 1) {
						ractive.set({ selectRingList: auction.ringList })
					} else {
						const stateName = forBroadcast ? 'audio-broadcast' : 'clerk'

						//TODO: include some sort of ring id as a route param here when we have such a thing
						stateRouter.go(`app.${stateName}`, { auctionId: auction.auctionNumber, authenticationToken, accountId: ractive.get('userId') })
					}
				})
			},
		},
		activate(context) {
			const { domApi: ractive } = context
			ractive.on('loginEvent', () => {
				ractive.login(ractive.get('username'), ractive.get('password'))
				return false
			})

			event(document, 'keydown', event => {
				if (ractive.get('selectAuctionModalShown') && event.key === 'Enter') {
					ractive.selectAuction(ractive.get('selectAuctionList')[ractive.get('selectedAuctionIndex')], null, ractive.get('forBroadcast'))
				}
			})

			ractive.observe('username password', () => {
				ractive.set({ errorMessage: '' })
			}, { init: false })
		},
	})
}
