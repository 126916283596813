const makeItButton = require('@isoftdata/button')
const makeItModal = require('@isoftdata/modal')
const makeItInput = require('@isoftdata/input')
const makeItTextarea = require('@isoftdata/textarea')

const event = require('@isoftdata/browser-event')
const { pluralIfNeeded } = require('@isoftdata/utility-string')
const { findKeyByValue } = require('@isoftdata/utility-object')
const { joinWithConjunction } = require('@isoftdata/utility-array')
const currencyUtil = require('@isoftdata/utility-currency')

const keyMapping = require('key-mapping')
const { MultipleLotType, BidType, ClientRole } = require('socket-server-api-constants')
const parseLotGroupString = require('@isoftdata/ax-lot-group-parser')
const parseLotString = require('@isoftdata/ax-lot-string-parser')
const number = require('financial-number')
const { klona } = require('klona')

const currencyFormat = (input, options = {}) => currencyUtil
	.format(input, { ...options, trimTrailing: true })

//This function is more efficient
const findLotIndex = (lotList, lotNumber, sublot) => {
	for (let index = 0; index < lotList.length; index++) {
		if (lotList[index].lotNumber === lotNumber && lotList[index].sublot === sublot) {
			return index
		}
	}
	return -1
}

const proxyBidsToMap = (proxyBidArray, proxyBidMap) => {
	for (const proxyBid of proxyBidArray) {
		proxyBidMap.set(`${proxyBid.lotNumber}${proxyBid.sublot}`, proxyBid)
	}

	return proxyBidMap
}

module.exports = ({ stateRouter, socket, socketEmit, mediator }) => {
	stateRouter.addState({
		name: 'app.clerk',
		route: '/clerk',
		querystringParameters: [ 'auctionId', 'accountId', 'authenticationToken' ],
		template: {
			template: require('./clerk.html'),
			components: {
				itButton: makeItButton(),
				itInput: makeItInput({ twoway: true, lazy: false }),
				winningBidderModal: makeItModal(),
				choiceGroupModal: makeItModal(),
				lotGroupingModal: makeItModal(),
				setBidModal: makeItModal(),
				setAskModal: makeItModal(),
				selectAuctionModal: makeItModal(),
				reconnectingModal: makeItModal(),
				selectAudioBroadcastRoomModal: makeItModal(),
				itTextarea: makeItTextarea({ twoway: true }),
			},
			data: {
				modalShown: {
					winningBidder: false,
					setBid: false,
					setAsk: false,
					groupLots: false,
					choiceGroup: false,
				},

				winningBidderNumber: null,

				isSettingBidAmount: false,
				newBidAmount: '',

				newAskAmount: '0',

				lotGroupingInput: '',
				currentLotGrouping: {},

				currentLot: {},
				audioRooms: [],
				activeBidders: {
					bidders: [],
					guests: 0,
				},
				checkForMissingBidderLimits: false,
				proxyBids: new Map(),
				auctionChat: 'Welcome to live auction chat!',
				lotMessages: {},

				mainKeys: keyMapping.mainKeys,
				lotKeys: keyMapping.lotKeys,
				actionKeys: keyMapping.actionKeys,

				currencyFormat,
				pluralIfNeeded,

				MultipleLotType,
				chosenLots: [],

				authenticationToken: '',
				reconnectingModalShown: false,
				keyguideShown: JSON.parse(localStorage.getItem('keyguideShown')) !== false,
			},
			computed: {
				displayMainKeys() {
					return this.get('mainKeys').map(key => {
						if (parseInt(key.buttonText, 10)) {
							key.buttonText = currencyFormat(key.buttonText)
						}

						return {
							...key,
							showKeyText: key.key !== key.buttonText,
						}
					})
				},
				displayLotKeys() {
					return this.get('lotKeys').map(key => {
						return {
							...key,
							showKeyText: key.key !== key.buttonText,
						}
					})
				},
				appraisedPerformanceProgressWidth() {
					const lot = this.get('displayCurrentLot')
					const currentBid = parseFloat(`${lot.bidding.bidDollars || '0'}.${lot.bidding.bidCents || '0'}`)

					return (currentBid / lot.appraisedPrice) * 100
				},
				displayCurrentLot() {
					const currentLot = this.get('currentLot')
					const lotList = this.get('lotList')
					const activeBidders = this.get('activeBidders.bidders') || []

					const { accountLimit, expenditure } = activeBidders.find(activeBidder => activeBidder.bidderNumber === currentLot.bidding.bidderNumber) || {}

					const lotListIndex = findLotIndex(lotList, currentLot.lotNumber, currentLot.sublot)

					return {
						...lotList[lotListIndex],
						...currentLot,
						bidding: {
							...currentLot.bidding,
							accountLimit: accountLimit || 0,
							expenditure: expenditure || 0,
							remainingCredit: parseFloat(accountLimit || 0) - parseFloat(expenditure || 0),
							announcementsAndDescription: `${currentLot.message}${currentLot.message ? '\n\n' : ''}${currentLot.description}`.trim(),
						},
					}
				},
				displayLotGrouping() {
					const lotList = this.get('lotList')
					const lotGroupingInput = this.get('lotGroupingInput')
					const chosenLots = this.get('chosenLots')

					return parseLotGroupString(lotGroupingInput, lotList).map(groupingLot => {
						const lotIndex = findLotIndex(lotList, groupingLot.lotNumber, groupingLot.sublot)
						return lotList[lotIndex]
					}).map(lot => {
						return {
							...lot,
							isChosenLot: !!chosenLots.find(chosenLot => chosenLot.lotNumber === lot.lotNumber && chosenLot.sublot === lot.sublot),
						}
					})
				},
				displayCurrentLotGrouping() {
					const currentLotGrouping = this.get('currentLotGrouping')
					const currentLot = this.get('currentLot')
					const lots = currentLotGrouping.lots || []
					let groupTypeName = ''
					let lotListString = ''

					if (currentLotGrouping && currentLotGrouping.groupType) {
						let matchingLot
						if (currentLotGrouping.groupType && Array.isArray(currentLotGrouping.lots)) {
							matchingLot = currentLotGrouping.lots.find(lot => {
								return lot.lotNumber === currentLot.lotNumber &&
									lot.sublot === currentLot.sublot
							})
						}

						if (matchingLot) {
							groupTypeName = currentLotGrouping.groupType.toLowerCase()
								.split('_')
								.map(word => word.charAt(0).toUpperCase() + word.slice(1))
								.join(' ')

							lotListString = joinWithConjunction(currentLotGrouping.lots.map(lot => `${lot.lotNumber}${lot.sublot}`))
						}
					}

					return {
						type: groupTypeName,
						lotList: lots,
						lotListString,
					}
				},
				displayLotList() {
					const lotGrouping = this.get('displayCurrentLotGrouping')
					const proxyBids = this.get('proxyBids')

					const lotList = this.get('lotList').map(lot => {
						const maxProxyBid = proxyBids.get(`${lot.lotNumber}${lot.sublot}`)
						if (maxProxyBid) {
							lot.maxProxyBid = maxProxyBid
						}

						return {
							...lot,
							description: [ parseInt(lot.year, 10), lot.manufacturer, lot.model, lot.inventoryTypeName ].filter(v => v).join(' '),
							isNoSale: lot.isSold && (lot.winningBidderNumber == 0 || lot.winningBidderNumber === -2),
						}
					})

					if (lotGrouping.lotList.length > 0) {
						let groupedLotsForList = lotList.filter(lot => {
							return lotGrouping.lotList.find(groupedLot => groupedLot.lotNumber === lot.lotNumber && groupedLot.sublot === lot.sublot)
						})

						const parentOfGroup = groupedLotsForList[0]
						const theRestOfTheGroup = groupedLotsForList.slice(1)

						return lotList.reduce((sum, lotFromList) => {
							const lotIsInTheGroup = theRestOfTheGroup.find(groupLot => groupLot.lotNumber === lotFromList.lotNumber && groupLot.sublot === lotFromList.sublot)

							if (lotIsInTheGroup) {
								const parentLotIndex = findLotIndex(sum, parentOfGroup.lotNumber, parentOfGroup.sublot)
								if (!sum[parentLotIndex].group) {
									sum[parentLotIndex] = klona({
										...sum[parentLotIndex],
										group: [],
									})
								}

								if (parentLotIndex > -1) {
									sum[parentLotIndex] = klona({
										...sum[parentLotIndex],
										group: [ ...sum[parentLotIndex].group, lotFromList ],
									})
									return sum
								}
								console.log('we should never be here')
								return sum
							}
							return [ ...sum, lotFromList ]
						}, [])
					}
					return lotList
				},
			},
			getLotListDescription({ lotNumber, sublot, description, sellerNumber, inventoryTypeName, isNoSale, isSold, winningBidderNumber, parentLotNumber, parentSublot }) {
				let computedDescription = `${lotNumber}${sublot} (O/C ${sellerNumber ? parseInt(sellerNumber, 10).toLocaleString('en') : '*Unknown*'}) - `

				if (isSold) {
					if (isNoSale) {
						//No sale means bidding is complete, but no one bought it
						computedDescription += `NO SALE`
					} else {
						computedDescription += `${this.getShortenedString(inventoryTypeName)} - Buyer #${winningBidderNumber}`

						if (parentLotNumber) {
							computedDescription += ` with ${parentLotNumber}${parentSublot}`
						}
					}
				} else {
					computedDescription += description
				}

				return computedDescription
			},
			getShortenedString(string = '', maxLength = 10) {
				const ellipse = '...'
				return string.length > maxLength ? `${string.substring(0, maxLength - ellipse.length).trim()}${ellipse}` : string
			},
			showGotoLotModal() {
				const lot = prompt('Enter lot to go to')
				const { lotNumber, sublot } = parseLotString(lot)

				this.changeLot(lotNumber, sublot)
			},
			lotIsInCurrentGroup(lotNumber, sublot) {
				const grouping = this.get('currentLotGrouping')
				console.log(lotNumber, sublot, grouping)
				if (grouping.lots) {
					return grouping.lots.some(lot => lot.lotNumber === lotNumber && lot.sublot === sublot)
				}
				return false
			},
			changeLot(newLotNumber, newSublot) {
				const currentLot = this.get('currentLot')

				console.log({
					previousLotNumber: currentLot.lotNumber,
					previousSublot: currentLot.sublot,
					newLotNumber,
					newSublot,
				})

				if (!(newLotNumber === currentLot.lotNumber && newSublot === currentLot.sublot)) {
					console.log(`going to lot ${newLotNumber}${newSublot}`)
					socketEmit('change lot', {
						previousLotNumber: currentLot.lotNumber,
						previousSublot: currentLot.sublot,
						newLotNumber,
						newSublot,
					})
				}
			},
			showSellLotModal() {
				const ractive = this
				const winningBidderNumber = ractive.get('currentLot.bidding.bidderNumber')

				ractive.set({
					'modalShown.winningBidder': true,
					'winningBidderNumber': winningBidderNumber == '-1' ? '' : winningBidderNumber.toString(),
				}).then(() => ractive.find('#winningBidderNumber').select())
			},
			async confirmSellLot(winningBidderNumber, event) {
				const ractive = this
				//NOTICE: we need to check for undefined explicitly here because a falsy check will catch bidderNumber 0 which means "no sale", which is valid.
				if (ractive.get('winningBidderNumber') === undefined) {
					return
				}

				if (event) {
					event.preventDefault()
				}

				await ractive.set({ isSellingLot: true })
				const { bidding, lotNumber, sublot } = ractive.get('currentLot')
				const { bidDollars, bidCents } = bidding

				const { nextLot } = await socketEmit('sold', {
					lotNumber,
					sublot,
					winningBidderNumber,
					bidDollars,
					bidCents,
				})

				this.changeLot(nextLot.lotNumber, nextLot.sublot)
				ractive.set({ 'isSellingLot': false, 'modalShown.winningBidder': false })
			},
			showSetBidModal() {
				const ractive = this

				ractive.set({
					'modalShown.setBid': true,
					'newBidAmount': '',
				}).then(() => {
					ractive.find('#newBidAmount').select()
				})
			},
			confirmNewBidAmount(lotNumber, sublot, amount, event) {
				const ractive = this
				ractive.set({ isSettingBidAmount: true }).then(() => {
					ractive.newBid(lotNumber, sublot, amount)
					ractive.set('modalShown.setBid', false)
				})

				if (event && event.event) {
					return event.event.preventDefault()
				}
			},
			showSetAskModal() {
				const ractive = this

				ractive.set({
					'modalShown.setAsk': true,
					'newAskAmount': '',
				}).then(() => {
					ractive.find('#newAskAmount').select()
				})
			},
			setNewAskAmount(amount, event) {
				const ractive = this
				const { lotNumber, sublot } = ractive.get('currentLot')
				const ask = amount.toString().split('.')

				socket.emit('new ask increment', { lotNumber, sublot, "askDollars": ask[0], "askCents": ask[1] })
				ractive.set('modalShown.setAsk', false)

				if (event) {
					return event.event.preventDefault()
				}
			},
			togglePauseState() {
				const ractive = this
				socket.emit(ractive.get('currentLot.bidding.isRingPaused') ? 'resume' : 'pause')
			},
			floor() {
				const ractive = this
				const { bidding, lotNumber, sublot } = ractive.get('currentLot')
				this.newBid(lotNumber, sublot, `${bidding.bidDollars}.${bidding.bidCents}`, false)
				socket.emit('auction chat message', {
					bidderNumber: bidding.bidderNumber,
					message: 'Sorry, but that bid went to the floor. Please bid again.',
				})
			},
			liftReserve() {
				const ractive = this
				let { lotNumber, sublot } = ractive.get('currentLot')
				socket.emit('remove reserve', { lotNumber, sublot })
				this.set('currentLot.reservePrice', '0.00')
			},
			endAuction() {
				socket.emit('suggest auction change')
			},
			showLotGroupingModal() {
				const ractive = this
				let { lotNumber, sublot } = ractive.get('currentLot')

				lotNumber = lotNumber || ''
				sublot = sublot || ''

				ractive.set({
					'modalShown.groupLots': true,
					'lotGroupingInput': ractive.get('lotGroupingInput') || `${lotNumber}${sublot}`,
				}).then(() => ractive.find('#lotGroupingInput').focus())
			},
			newBidIncrement(increment) {
				const { lotNumber, sublot, bidding } = this.get('currentLot')

				const newBidAmount = number(`${bidding.bidDollars}.${bidding.bidCents}`)
					.plus(increment).toString()

				this.newBid(lotNumber, sublot, newBidAmount, false)
			},
			newBid(lotNumber, sublot, newBidAmount, override = true) {
				const [ newBidDollars, newBidCents ] = newBidAmount.toString().split('.')

				socket.emit(`new ${override ? 'override ' : ''}bid`, {
					bidderNumber: -1,
					lotNumber,
					sublot,
					isInternetBidder: false,
					bidDollars: parseInt(newBidDollars, 10) || 0,
					bidCents: parseInt(newBidCents, 10) || 0,
					bidderLocation: 'Onsite',
				})
			},
			undoBid() {
				const { lotNumber, sublot } = this.get('currentLot')
				socket.emit('undo bid', { lotNumber, sublot })
			},
			redoBid() {
				const { lotNumber, sublot } = this.get('currentLot')
				socket.emit('redo bid', { lotNumber, sublot })
			},
			resetBidding() {
				const { lotNumber, sublot } = this.get('currentLot')
				socket.emit('reset bidding', { lotNumber, sublot })
				socket.emit('append lot message', { lotNumber, sublot, "message": "Bidding on this item has been reset." })
				/*this.changeLot(currentLot.lotNumber, currentLot.sublot)*/
			},
			groupLots(groupType, lotGroupingList) {
				const lots = lotGroupingList.map(({ lotNumber, sublot }) => {
					return { lotNumber, sublot }
				})

				socket.emit('group', { groupType, lots })
				this.set('modalShown.groupLots', false)
			},
			ungroupLots() {
				socket.emit('ungroup')
			},
			reloadLot() {
				socket.emit('flush lot cache')
			},
			getCurrentLotIndex() {
				const { lotNumber, sublot } = this.get('currentLot')
				return findLotIndex(this.get('lotList'), lotNumber, sublot)
			},
			moveToPreviousLot() {
				const currentLotIndex = this.getCurrentLotIndex()
				const previousLot = this.get('lotList')[currentLotIndex - 1]

				if (previousLot) {
					this.changeLot(previousLot.lotNumber, previousLot.sublot)
				}
			},
			moveToNextLot() {
				//TODO: Hit some endpoint to figure out the next lot
				const nextLot = this.get('lotList')[this.getCurrentLotIndex() + 1]

				if (nextLot) {
					this.changeLot(nextLot.lotNumber, nextLot.sublot)
				}
			},
			bidMaxProxy() {
				const { lotNumber, sublot } = this.get('currentLot')
				socket.emit('bid to max proxy', { lotNumber, sublot })
			},
			simulateKeydown(key) {
				//Called when they click the button in the UI
				//This just simulates a real keyboard event
				document.dispatchEvent(new KeyboardEvent('keydown', { key }))
			},
			showChoiceGroupModal(chosenLots = []) {
				const ractive = this
				ractive.set({ 'modalShown.choiceGroup': true, chosenLots }).then(() => {
					//ractive.find('#choiceGroupInput').focus()
				})
			},
			sellSingleLotFromGroupToBidder(lot) {
				const bidderNumber = this.get('winningBidderNumber')
				socket.emit('choose', { bidderNumber, lots: [ lot ] }, () => {})
				this.set({ 'isSellingLot': false, 'modalShown.winningBidder': false })
			},
			chooseLots(selectedLots, event) {
				const ractive = this
				const lotList = ractive.get('lotList')
				console.log("Choosing Lots!")
				let lotGroupArray = []
				selectedLots.forEach(lot => {
					lotGroupArray.push(`${lot.lotNumber}${lot.sublot}`)
				})
				const lots = parseLotGroupString(lotGroupArray.toString(), lotList)
				const bidderNumber = ractive.get('winningBidderNumber')
				socket.emit('choose', { bidderNumber, lots }, nextLot => {
					console.log(nextLot)
				})
				ractive.set({ 'modalShown.choiceGroup': false, 'modalShown.winningBidder': false, 'isSellingLot': false })
			},
			async loadLot(lotNumber, sublot) {
				console.time('loadLot socket loads')
				const [ currentLot, lotMessage, lotImages, biddingDetails ] = await Promise.all([
					socketEmit('get lot details', { lotNumber, sublot }),
					socketEmit('get lot message', { lotNumber, sublot }),
					socketEmit('get lot images', { lotNumber, sublot }),
					socketEmit('get bidding details', { lotNumber, sublot }),
				])
				console.timeEnd('loadLot socket loads')

				console.time('load lot find proxy')
				const proxyBid = this.get('proxyBids').get(`${lotNumber}${sublot}`)
				console.timeEnd('load lot find proxy')

				return {
					currentLot,
					lotMessage,
					lotImages,
					biddingDetails,
					proxyBid,
				}
			},
			async loadAndSetCurrentLot(lotNumber, sublot) {
				const ractive = this

				let {
					currentLot,
					lotMessage,
					lotImages,
					biddingDetails,
					proxyBid,
				} = await ractive.loadLot(lotNumber, sublot)
				if (proxyBid && biddingDetails.maxProxyBid != proxyBid.bidDollars) {
					// proxy bid on the lot list is out of date, prefer the one from the bidding details
					const proxyDollars = biddingDetails.maxProxyBid.toString().split('.')
					proxyBid.bidDollars = Number(proxyDollars[0])
					proxyBid.bidderNumber = biddingDetails.bidderNumber
					console.log(proxyBid)
				} else if (!proxyBid && biddingDetails.maxProxyBid) {
					const proxyDollars = biddingDetails.maxProxyBid.toString().split('.')
					proxyBid = { "bidDollars": Number(proxyDollars[0]), "bidderNumber": biddingDetails.bidderNumber }
				}

				ractive.set({
					currentLot: {
						...currentLot,
						bidding: {
							...biddingDetails,
							proxyBid,
						},
						...lotImages,
						message: lotMessage.message || '',
					},
				}).then(() => {
					//Scroll the current lot item to the top(showing the previous lot as well).
					const lotListItemEl = ractive.find(`#lot-list-item-button-${ractive.getCurrentLotIndex()}`)

					if (lotListItemEl) {
						ractive.find('#lot-list').scrollTop = (lotListItemEl.offsetTop - 400)
					}
				})
			},
			toggleAllChosenLots() {
				const lotGrouping = this.get('displayLotGrouping')
				const chosenLots = this.get('chosenLots')

				if (chosenLots.length === lotGrouping.length) {
					this.set({ chosenLots: [] })
				} else {
					this.set({
						chosenLots: lotGrouping.map(groupLot => {
							return { lotNumber: groupLot.lotNumber, sublot: groupLot.sublot }
						}),
					})
				}
			},
			toggleChosenLot(lotNumber, sublot) {
				const matchingLotIndex = this.get('chosenLots')
					.findIndex(chosenLot => chosenLot.lotNumber === lotNumber && chosenLot.sublot === sublot)

				if (matchingLotIndex > -1) {
					this.splice('chosenLots', matchingLotIndex, 1)
				} else {
					this.push('chosenLots', { lotNumber, sublot })
				}
			},
			registerKeyListeners(stateRouterContext) {
				const ractive = this

				const removeDocumentKeydownListener = event(document, 'keydown', event => {
					const keyguideShown = ractive.get('keyguideShown')
					const matchingKey = [
						...ractive.get('mainKeys'),
						...ractive.get('lotKeys'),
						...ractive.get('actionKeys'),
					].find(key => key.key === event.key)
					//Don't want to fire these events when an input has focus
					//Or when a modal is shown
					if (keyguideShown && matchingKey &&
						document.activeElement.tagName !== 'INPUT' &&
						!Object.values(ractive.get('modalShown')).some(isShown => isShown)) {
						//The ractive function to call is bound to the key
						ractive.find(`#${matchingKey.id}`).focus()
						matchingKey.fn(ractive)

						return event.preventDefault()
					}

					if (ractive.get('modalShown.groupLots') && event.ctrlKey) {
						if (event.key === 'a') {
							ractive.find('#lot-grouping-all-one-money').click()
						} else if (event.key === 'e') {
							ractive.find('#lot-grouping-each-times-money').click()
						} else if (event.key === 'c') {
							ractive.find('#lot-grouping-choice').click()
						} else {
							return
						}

						return event.preventDefault()
					}

					if (ractive.get('modalShown.choiceGroup') && event.key === 'Enter') {
						ractive.find('#lot-choice-confirm-choices').click()
					}

					if (ractive.get('modalShown.setAsk')) {
						if (event.key === '1') {
							ractive.set('newAskAmount', '25.00')
						} else if (event.key === '2') {
							ractive.set('newAskAmount', '50.00')
						} else if (event.key === '3') {
							ractive.set('newAskAmount', '100.00')
						} else if (event.key === '0') {
							ractive.set('newAskAmount', '0.0')
						} else if (event.key === '4') {
							ractive.set('newAskAmount', '250.00')
						} else if (event.key === '5') {
							ractive.set('newAskAmount', '500.00')
						} else if (event.key === '6') {
							ractive.set('newAskAmount', '1000.00')
						} else if (event.key === '7') {
							ractive.set('newAskAmount', '2500.00')
						} else if (event.key === '8') {
							ractive.set('newAskAmount', '5000.00')
						} else if (event.key === '9') {
							ractive.set('newAskAmount', '10000.00')
						} else if (event.key === 'Enter') {
							return
						}
						return event.preventDefault()
					}
				})

				const auctioneersAnnouncements = ractive.find('#auctioneersAnnouncements')
				const removeAnnoucementsTextareaKeyDownListener = event(auctioneersAnnouncements, 'keydown', event => {
					handleMessageEvent(event, auctioneersAnnouncements, message => {
						const { lotNumber, sublot } = ractive.get('displayCurrentLot')

						socket.emit('append lot message', { lotNumber, sublot, message })
					})
				})

				const auctionChatTextarea = ractive.find('#chatMessage')
				const removeAuctionChatKeyDownListener = event(auctionChatTextarea, 'keydown', event => {
					handleMessageEvent(event, auctionChatTextarea, message => {
						socket.emit('auction chat message', { message })
					})
				})

				stateRouterContext.on('destroy', () => {
					//remove all the socket listeners we've registered when this state gets destroyed
					socket.off()

					removeDocumentKeydownListener()
					removeAuctionChatKeyDownListener()
					removeAnnoucementsTextareaKeyDownListener()
				})

				function handleMessageEvent(event, el, action) {
					event.stopPropagation()
					if (event.key === 'Enter') {
						event.preventDefault()
						action(el.value)
						el.value = ''
						if (ractive.get('keyguideShown')) {
							el.blur()
						}
					} else if (event.key === 'Escape') {
						el.blur()
					}
				}
			},
			appendChatMessage(chatEntry) {
				const ractive = this
				const messageTimestamp = new Date(chatEntry.timestamp)
				const formattedTime = messageTimestamp.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })

				return ractive.set({ auctionChat: `${ractive.get('auctionChat')}<br>(${formattedTime}) ${chatEntry.message}` })
			},
			registerSocketListeners() {
				const ractive = this

				socket.on('bidder join', ({ bidderNumber, bidderName, bidderLocation, bidderCompany, expenditure, accountLimit }) => {
					console.log(ractive.get('activeBidders.bidders'))
					ractive.set('checkForMissingBidderLimits', true)
					const index = ractive.get('activeBidders.bidders').findIndex(bidder => bidder.bidderNumber === bidderNumber)
					if (index > -1) {
						const bidder = ractive.get('activeBidders.bidders').find(bidder => bidder.bidderNumber === bidderNumber)
						ractive.set(`activeBidders.bidders.${index}.count`, bidder.count + 1)
					} else {
						ractive.push('activeBidders.bidders', {
							bidderNumber,
							bidderLocation,
							bidderName,
							interested: false,
							count: 1,
							bidderCompany,
							expenditure,
							accountLimit,
						})
					}
				})

				socket.on('guest count changed', ({ guestCount }) => {
					ractive.set('activeBidders.guests', guestCount)
				})

				socket.on('bidder leave', ({ bidderNumber }) => {
					const index = ractive.get('activeBidders.bidders')
						.findIndex(bidder => bidder.bidderNumber === bidderNumber)
					if (index > -1) {
						const bidder = ractive.get('activeBidders.bidders').find(bidder => bidder.bidderNumber === bidderNumber)
						if (bidder && bidder.count > 1) {
							ractive.set(`activeBidders.bidders.${index}.count`, bidder.count - 1)
						} else {
							ractive.splice('activeBidders.bidders', index, 1)
						}
					}
				})

				socket.on('bidder interest', ({ bidderNumber, interested }) => {
					const index = ractive.get('activeBidders.bidders')
						.findIndex(bidder => bidder.bidderNumber === bidderNumber)
					if (index > -1) {
						ractive.set(`activeBidders.bidders.${index}.interested`, interested)
					}
				})

				socket.on('change lot', ({ newLotNumber: lotNumber, newSublot: sublot }) => {
					console.log(`Lot was changed to ${lotNumber}${sublot}`)
					// if this is a modulo 2 lot, check for missing proxy bids?
					if (lotNumber % 2 == 0) {
						socketEmit('get proxy bid list', {}).then(proxyBidsArray => {
							//Use an entirly new Map here since we loaded the full proxy bid array
							ractive.set({ proxyBids: proxyBidsToMap(proxyBidsArray, new Map()) })
						})
					}
					//reset interest to false for all active bidders
					const bidders = ractive.get('activeBidders.bidders').map(bidder => {
						return { ...bidder, interested: false }
					})
					ractive.set(`activeBidders.bidders`, bidders)
					ractive.loadAndSetCurrentLot(lotNumber, sublot)

					//This let's the parent state(with the header bar) know that the seller changed
					mediator.call('seller changed', ractive.get('currentLot.sellerNumber'))
				})

				socket.on('lot message', ({ lotNumber, sublot, message }) => {
					const currentLot = ractive.get('currentLot')
					//console.log(lotNumber, sublot, message)
					if (lotNumber == currentLot.lotNumber && sublot == currentLot.sublot) {
						ractive.set(`currentLot.message`, message)
					}
				})

				socket.on('auction chat message', async chatEntry => {
					await ractive.appendChatMessage(chatEntry)
					ractive.find('#chatContent').scrollTop = ractive.find('#chatContent').scrollHeight
				})
				socket.on('pause', () => ractive.set('currentLot.bidding.isRingPaused', true))
				socket.on('resume', () => ractive.set('currentLot.bidding.isRingPaused', false))
				socket.on('group', res => {
					ractive.changeLot(res.lots[0].lotNumber, res.lots[0].sublot)

					console.log('group', res)
					ractive.set('lotGroupingInput', res.lots.reduce((acc, cur) =>
						acc.concat(`${cur.lotNumber}${cur.sublot}`), [],
					).join())
					const groupType = findKeyByValue(ractive.get('MultipleLotType'), res.groupType)
					ractive.set({ currentLotGrouping: { ...res, groupType } })
				})

				socket.on('reset bidding', biddingData => {
					const { lotNumber, sublot, ...bidding } = biddingData
					const currentLot = ractive.get('currentLot')
					const lotList = ractive.get('lotList')
					// TODO: set isSold to false in the Lot List for this lot
					/*ractive.set(ractive.get('lotList').find(lot => {
						return lot.lotNumber === lotNumber &&
								lot.sublot === sublot
					}).isSold, false)*/
					// get the lot from the lotList and set its variables accordingly
					let lotIndex = findLotIndex(lotList, lotNumber, sublot)

					ractive.splice('lotList', lotIndex, 1, {
						...lotList[lotIndex],
						isSold: false,
						winningBidderNumber: 0,
						winningBid: `0.00`,
					})

					if (lotNumber === currentLot.lotNumber && sublot === currentLot.sublot) {
						ractive.set({
							currentLot: {
								...currentLot,
								isSold: false,
								bidding: {
									...currentLot.bidding,
									...bidding,
								},
							},
						})
					}
				})

				socket.on('new bid', biddingData => {
					console.log('new bid', biddingData)
					const { lotNumber, sublot, ...bidding } = biddingData
					const currentLot = ractive.get('currentLot')

					if (lotNumber === currentLot.lotNumber && sublot === currentLot.sublot) {
						ractive.set('currentLot.bidding', { ...ractive.get('currentLot.bidding'), ...bidding })

						const activeBidderIndex = ractive.get('activeBidders.bidders').findIndex(activeBidder => activeBidder.bidderNumber == biddingData.bidderNumber)

						if (activeBidderIndex > -1) {
							ractive.splice('activeBidders.bidders', activeBidderIndex, 1, {
								...ractive.get('activeBidders.bidders')[activeBidderIndex],
								lastBidTime: new Date(),
							})
						}

						const node = ractive.find('#currentBid')
						node.animate({ 'backgroundColor': [ '#ff000059', 'white' ] }, { duration: 500 })
						node.animate({ 'color': [ 'black', 'red' ] }, { duration: 500 })
					} else if (biddingData.bidType === BidType.INTERNET) {
						//It's a proxy bid
						const proxyBidMap = ractive.get('proxyBids')
						ractive.set({ proxyBids: proxyBidMap.set(`${lotNumber}${sublot}`, biddingData) })
					}
				})

				socket.on('ungroup', () => {
					ractive.set({
						currentLotGrouping: {},
						lotGroupingInput: '',
					})
				})

				socket.on('set choice', ({ selectedLots: choiceLots }) => {
					const choices = ractive.get('lotList').filter(lot => {
						return choiceLots.find(choiceLot => {
							return choiceLot.lotNumber === lot.lotNumber
								&& choiceLot.sublot === lot.sublot
						})
					})
					this.showChoiceGroupModal(choices)
				})

				socket.on('new ask', ({ lotNumber, sublot, askDollars, askCents }) => {
					// {lotNumber, sublot, askDollars, askCents}
					const currentLot = ractive.get('currentLot')
					if (lotNumber === currentLot.lotNumber && sublot === currentLot.sublot) {
						ractive.set({ 'currentLot.bidding.askDollars': askDollars, 'currentLot.bidding.askCents': askCents })
					}
				})

				socket.on('sold', data => {
					ractive.set({ 'modalShown.choiceGroup': false, 'modalShown.winningBidder': false, 'isSellingLot': false })
					const { lotNumber, sublot, bidderNumber, bidDollars, bidCents } = data
					const lotList = ractive.get('lotList')
					const lotIndex = findLotIndex(lotList, lotNumber, sublot)

					if (lotIndex > -1) {
						ractive.splice('lotList', lotIndex, 1, {
							...lotList[lotIndex],
							isSold: true,
							winningBidderNumber: bidderNumber,
							winningBid: `${bidDollars}.${bidCents}`,
						})
					}

					const bidderWinningLotsCount = ractive.get('bidderWinningLotsCount')
					ractive.set({
						lotGroupingInput: '',
						bidderWinningLotsCount: {
							...bidderWinningLotsCount,
							[bidderNumber]: (bidderWinningLotsCount[bidderNumber] || 0) + 1,
						},
					})
					socket.emit('get credit limit', { bidderNumber }, ({ accountLimit, expenditure }) => {
						const index = ractive.get('activeBidders.bidders').findIndex(bidder => bidder.bidderNumber === parseInt(bidderNumber, 10))
						if (index > -1) {
							ractive.set(`activeBidders.bidders.${index}.expenditure`, expenditure)
							ractive.set(`activeBidders.bidders.${index}.accountLimit`, accountLimit)
						}
					})
				})

				socket.on('require authentication', (request, callback) => {
					socket.emit('reauthenticate', {
						loginToken: ractive.get('authenticationToken'),
						auctionNumber: ractive.get('currentAuction.auctionNumber'),
						ringId: 0,
						role: ClientRole.CLERK,
						accountId: ractive.get('accountId'),
					}, () =>{
						if (callback && request.payload) {
							socket.emit(request.message, request.payload, callback)
						} else if (request.payload) {
							socket.emit(request.message, request.payload)
						} else if (callback) {
							socket.emit(request.message, callback)
						} else {
							socket.emit(request.message)
						}
					})
				})

				socket.on('reconnect', () => {
					console.log("Reconnecting")
					socket.emit('reauthenticate', {
						loginToken: ractive.get('authenticationToken'),
						auctionNumber: ractive.get('currentAuction.auctionNumber'),
						ringId: 0,
						role: ClientRole.CLERK,
						accountId: ractive.get('accountId'),
					}, async() =>{
						try {
						// if we have to reconnect, we should have the server change to our current lot, just in case it crashed
							const { lotNumber, sublot } = ractive.get('currentLot')
							const keyguideShown = ractive.get('keyguideShown')
							const bidders = await socketEmit('get bidders', {})
							ractive.set({ activeBidders: bidders })
							const proxyBidsArray = await socketEmit('get proxy bid list', {})
							ractive.set({ proxyBids: proxyBidsToMap(proxyBidsArray, ractive.get('proxyBids')) })
							// we have to ask the server what lot it *thinks* it's on before we issue a 'change lot' message
							// or the server will gleefully ignore our request.
							const serverLot = await socketEmit('get current lot', {})
							// TODO: change this to not compare against lot 1
							// but instead ask the server what the "first lot" is, and compare against that.
							console.log(keyguideShown)
							if (keyguideShown && serverLot && serverLot.lotNumber < lotNumber) {
								socketEmit('change lot', {
									previousLotNumber: serverLot.lotNumber,
									previousSublot: serverLot.sublot,
									newLotNumber: lotNumber,
									newSublot: sublot,
								})
							} else if (serverLot) {
								ractive.loadAndSetCurrentLot(serverLot.lotNumber, serverLot.sublot)
							}

							const grouping = ractive.get('currentLotGrouping')
							if (grouping && grouping.lots && grouping.lots.length > 0 && grouping.groupType) {
								ractive.groupLots(grouping.groupType, grouping.lots)
							}
						} catch (err) {
							console.err("There was a problem setting the clerking environment up after reauthenticating with the server.")
						}
					})
				})

				socket.on('suggest auction change', ({ oldAuctionNumber, newAuctionNumber }) => {
					let auctionNumber = ractive.get('currentAuction.auctionNumber')
					if (auctionNumber == oldAuctionNumber) {
						location.reload()
					}
				})
			},
		},
		async resolve(data, { auctionId, authenticationToken, accountId }) {
			const { isLoggedIn, isClerk } = await socketEmit('get login status')

			if (!isLoggedIn || !isClerk) {
				return Promise.reject({ redirectTo: { name: 'login' } })
			}

			await socketEmit('set auction', { auctionNumber: parseInt(auctionId, 10) })
			//TODO: actually handle the ring here
			await socketEmit('set ring', { ringid: 0 })

			const [ auctionList, lotList ] = await Promise.all([
				socketEmit('get auctions', {}),
				socketEmit('get lot list', {}),
			])

			let bidderWinningLotsCount = {}
			for (const lot of lotList) {
				bidderWinningLotsCount[lot.winningBidderNumber] = (bidderWinningLotsCount[lot.winningBidderNumber] || 0) + 1
			}

			return {
				auctionList,
				currentAuction: auctionId ? auctionList.find(auction => auction.auctionNumber == auctionId) : null,
				lotList,
				bidderWinningLotsCount,
				authenticationToken,
				accountId: parseInt(accountId, 10),
			}
		},
		activate(stateRouterContext) {
			const ractive = stateRouterContext.domApi

			socket.on('connect', () => ractive.set('reconnectingModalShown', false))

			socket.on('disconnect', reason => {
				ractive.set('reconnectingModalShown', true)
				if (reason === 'io server disconnect') {
					// the disconnection was initiated by the server, you need to reconnect manually
					socket.connect()
				}
				// else the socket will automatically try to reconnect
			})

			Promise.all([
				socketEmit('get current lot', {}),
				socketEmit('get proxy bid list', {}),
			]).then(([ currentLot, proxyBidsArray ]) => {
				//make sure we set the proxy bids before we load the current lot
				const proxyBidMap = ractive.get('proxyBids') //get the map
				ractive.set({ proxyBids: proxyBidsToMap(proxyBidsArray, proxyBidMap) })
				ractive.loadAndSetCurrentLot(currentLot.lotNumber, currentLot.sublot)
			})

			socketEmit('get chat history').then(chatHistory => {
				chatHistory.forEach(chatEntry => {
					ractive.appendChatMessage(chatEntry).then(() => {
						//ractive.find('#chatContent').scrollTop = ractive.find('#chatContent').scrollHeight
					})
				})
				ractive.find('#chatContent').scrollTop = ractive.find('#chatContent').scrollHeight
			})

			socketEmit('get bidders', {}).then(bidders => {
				console.log(bidders)
				ractive.set({ activeBidders: bidders })
				console.log(ractive.get('activeBidders.bidders'))
			})

			ractive.registerKeyListeners(stateRouterContext)
			ractive.registerSocketListeners()

			ractive.observe('activeBidders.bidders.*.lastBidTime activeBidders.bidders.*.interested', () => {
				console.log("bidders observer fired")
				ractive.sort('activeBidders.bidders', (a, b) => {
					if (a.lastBidTime && b.lastBidTime) {
						return (a.lastBidTime > b.lastBidTime) ? -1 : 1
					} else if (!a.lastBidTime && !b.lastBidTime) {
						return (a.interested && !b.interested) ? -1 : 1
					}
					return 0
				})
			})

			ractive.observe('checkForMissingBidderLimits', (newValue, oldValue, keypath) =>{
				if (newValue) {
					console.log("Checking for missing bidder limits")
					const biddersMissingLimits = ractive.get('activeBidders.bidders').filter(bidder => bidder.accountLimit.toString() === '0')
					biddersMissingLimits.forEach(bidder => {
						const bidderNumber = bidder.bidderNumber
						socket.emit('get credit limit', { bidderNumber }, ({ accountLimit, expenditure }) => {
							const index = ractive.get('activeBidders.bidders').findIndex(b => b.bidderNumber === parseInt(bidderNumber, 10))
							if (index > -1) {
								ractive.set(`activeBidders.bidders.${index}.expenditure`, expenditure)
								ractive.set(`activeBidders.bidders.${index}.accountLimit`, accountLimit)
							}
						})
					})
					ractive.set('checkForMissingBidderLimits', false)
				}
			})

			ractive.observe('modalShown.winningBidder', selling => {
				if (selling) {
					socket.emit('pause')
				} else {
					socket.emit('resume')
				}
			}, { "init": false })

			/* socket.emit('load rooms', (err, audioRooms) => {
				ractive.set({ audioRooms })
			}) */

			ractive.observe('keyguideShown', keyguideShown => {
				localStorage.setItem('keyguideShown', JSON.stringify(keyguideShown))
			}, { init: false })
		},
	})
}
