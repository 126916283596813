const makeItButton = require('@isoftdata/button')

module.exports = ({ stateRouter, socket }) => {
	stateRouter.addState({
		name: 'app.audio-broadcast',
		route: '/audio-broadcast',
		querystringParameters: ['auctionId'],
		template: {
			template: require('./audio-broadcast.html'),
			components: {
				itButton: makeItButton(),
			},
			data: {
				'audioEnabled': true,
				'listeners': [],
			},
		},
		resolve(data, parameters, cb) {
			cb()
		},
		activate({ domApi: ractive, parameters }) {
			audioinit('auctioneer', 'auctioneer-audio', true, parameters.auctionId, 1, ractive)
			/*socket.emit('join auction ring audio', parameters.auctionId, 1, true, () => {
				console.log('joined the room!')
				const audioEl = this.find('#auctioneer-audio')
				console.log(audioEl)
				try {
					const stream = await getMedia({ audio: true, video: false })

					audioEl.srcObject = stream
					const peer = RTCPeerConnection({
						iceServers: [
							{ urls: "stun:stun.services.mozilla.com" },
							{ urls: "stun:stun.l.google.com:19302" },
							{ urls: "stun:stun1.l.google.com:19302" },
							{ urls: "stun:stun2.l.google.com:19302" },
							{ urls: "stun:stun3.l.google.com:19302" },
							{ urls: "stun:stun4.l.google.com:19302" },
						],
					})
				} catch (err) {
					console.log(err)
					//handle error
				}
			})*/
			ractive.on('toggleMute', () => {
				audioenabled = !audioenabled
				ractive.set({ audioEnabled: audioenabled })
				mixertest.send({ message: { "request": "configure", "muted": !audioenabled } })
			})
		},
	})
}

async function getMedia(constraints) {
	try {
		return await navigator.mediaDevices.getUserMedia(constraints)
		/* use the stream */
	} catch (err) {
		console.log(err)
		return null
		/* handle the error */
	}
}
