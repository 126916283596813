const StateRouter = require('abstract-state-router')
const makeRactiveStateRenderer = require('ractive-state-router')
const mediator = require('mannish')()
// eslint-disable-next-line no-undef
const renderer = makeRactiveStateRenderer(Ractive)

const stateRouter = StateRouter(renderer, 'body')

// eslint-disable-next-line no-undef
const socket = io('https://axlive.berryhillauctioneers.com:8080/')

// eslint-disable-next-line no-undef
//const socket = io('http://localhost:8080')

stateRouter.addState({
	name: 'app',
	querystringParameters: [ 'auctionId' ],
	template: {
		template: require('./app.html'),
		data: {
			auctionList: [],
			auctionId: null,
			sellerNumber: null,
		},
		computed: {
			auction() {
				return this.get('auctionList')
					.find(auction => auction.auctionNumber === this.get('auctionId'))
			},
		},
	},
	async resolve(data, parameters) {
		const auctionList = await socketEmit('get auctions', {})

		return {
			auctionList,
			auctionId: parseInt(parameters.auctionId, 10) || null,
		}
	},
	activate(context) {
		const ractive = context.domApi

		ractive.observe('auction', v => console.log(v))

		const removeSellerChangedProvider = mediator
			.provide('seller changed', sellerNumber => ractive.set({ sellerNumber }))

		context.on('destroy', removeSellerChangedProvider)
	},
})

//Promise-based socket emit
const socketEmit = (...args) => {
	return new Promise(resolve => {
		const timerName = typeof args[0] === 'string' ? args[0] : 'unknown'
		console.time(timerName)
		const cb = (...response) => {
			console.timeEnd(timerName)
			return resolve(response[0])
		}
		socket.emit(...args, cb)
	})
}

//States
[
	require('./login/login.js'),
	require('./audio-broadcast/audio-broadcast.js'),
	require('./clerk/clerk.js'),
].forEach(createState => createState({ stateRouter, socket, mediator, socketEmit }))

socket.on('connect', () => {
	console.log('connected to socket server!')
	stateRouter.evaluateCurrentRoute('login')
})

